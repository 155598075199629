import { Outlet } from "react-router-dom";
// import { Link } from "react-router-dom";

export default function Layout() {

  // return (
  //   <div>
  //     <nav>
  //       <ul>
  //         <li>
  //           <Link to="/">Home</Link>
  //         </li>
  //         <li>
  //           <Link to="/upload">Upload</Link>
  //         </li>
  //         <li>
  //           <Link to="/login">Login</Link>
  //         </li>
  //         <li>
  //           <Link to="/nothing-here">Nothing Here</Link>
  //         </li>
  //       </ul>
  //     </nav>

  //     <hr />
  //     <Outlet />
  //   </div>
  // )

  return (
    <Outlet />
  );
}
