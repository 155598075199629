import { addDoc, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";

export const createEdition = async (edition: any) => {
    const dateStr = edition.date.toLocaleDateString('en-GB');

    const q = query(collection(db, "epapers"), where("dateStr", "==", dateStr));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        console.log("epaper updated")
    } else {
        await addDoc(collection(db, 'epapers'), edition);
    }
};

export const getEditions = async () => {
    const notesSnapshot = await getDocs(query(collection(db, "epapers"), orderBy('date', 'desc')));
    const editionList = notesSnapshot.docs.map((doc) => {
        const data = doc.data();
        const edition = { file: data.file, date: data.date.toDate().toLocaleDateString('en-GB'), dateStr: data.dateStr }
        return edition;
    });
    return editionList;
};