import { Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./components/Home";
import Layout from "./components/Layout";
import Login from "./components/Login";
import NoMatch from "./components/NoMatch";
import Reset from "./components/Reset";
import UploadFormContainer from "./components/UploadForm";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="epapers/:date" element={<Home />} />
        <Route path="epapers/:date/:pageNo" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="reset" element={<Reset />} />
        <Route path="upload" element={<UploadFormContainer />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
