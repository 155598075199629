import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../firebase";
import banner from '../images/banner.png';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (user) navigate("/upload");
        if (error) console.log(error);
    }, [user, loading, error, navigate]);

    return (
        !loading ? <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col md={6} lg={4} xs={12}>
                    <Card className="shadow">
                        <Card.Body>
                            <div className="mb-3 mt-3">
                                <div className="d-flex justify-content-center pb-5">
                                    <img src={banner} className="logo-sm" alt="logo" />
                                </div>
                                <div className="mb-3">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="text-center">
                                                Email address
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCheckbox"
                                        ></Form.Group>
                                        <div className="d-grid">
                                            <Button
                                                variant="danger"
                                                type="button"
                                                onClick={async () => {
                                                    await logInWithEmailAndPassword(email, password)
                                                    navigate("/upload")
                                                }
                                                }
                                            >
                                                Login
                                            </Button>
                                            <p className="small pt-3">
                                                <Link className="text-primary" to="/reset">
                                                    Reset Password
                                                </Link>
                                            </p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container> : <></>
    );
}

export default Login;
