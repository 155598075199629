import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useCallback, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { CalendarWeek } from "react-bootstrap-icons";
import Pagination from 'react-bootstrap/Pagination';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { BounceLoader } from 'react-spinners';
import banner from '../images/banner.png';
import { getEditions } from "../services/edition.service";
import './PdfViewer.scss';

import { useParams } from 'react-router';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
};


function Loader() {
    return <div className='d-flex justify-content-center pt-5 mt-4'>
        <BounceLoader color="#ff0000" />
    </div>
}


function Home() {
    const [numPages, setNumPages] = useState<number>(1);
    const [items, setItems] = useState<number[]>([]);
    const [active, setActive] = useState<number>(1);
    const [pdfLoaded, setPdfLoaded] = useState<boolean>(false);
    const params = useParams();

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy) {
        if (numPages < active) {
            setActive(1);
        }
        setNumPages(nextNumPages);

        const tempItems = []
        for (let number = 1; number <= nextNumPages; number++) {
            tempItems.push(number);
        }
        setItems(tempItems);
        setPdfLoaded(true);
    }

    const [editions, setEditions] = useState<any[]>([]);
    const [selectedEdition, setSelectedEditions] = useState<any>();

    const loadEditions = useCallback(async () => {
        const editionList = await getEditions()
        setEditions(editionList);

        const selectedEpaper = editionList.find((e: any) => e.dateStr === params.date);

        if (selectedEpaper) {
            setSelectedEditions(selectedEpaper);
            const pageNo = parseInt(params.pageNo as string);
            if (!!pageNo) {
                setActive(pageNo);
            }
        } else {
            setSelectedEditions(editionList[0]);
        }
    }, [params.date, params.pageNo]);

    useEffect(() => {
        loadEditions();
    }, [loadEditions])


    const handleDateChange = (date: string) => {
        setPdfLoaded(false);
        const edition = editions.find(e => e.date === date);
        setSelectedEditions(edition);
    }

    return (
        <>
            <div className="d-flex justify-content-center pt-2 pb-2">
                <img src={banner} className="logo" alt="logo" />
            </div>

            {selectedEdition && <div className="d-flex justify-content-center pt-2">
                <div className="date-container">
                    <Form>
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                                <CalendarWeek />
                            </InputGroup.Text>
                            <Form.Select aria-label="Default select example" value={selectedEdition ? selectedEdition.date : ''} onChange={(e: any) => handleDateChange(e.target.value)}>
                                {editions.map((variant) => <option key={variant.date} value={variant.date}>{variant.date}</option>)}
                            </Form.Select>
                        </InputGroup>
                    </Form>
                </div>
            </div>}

            {selectedEdition && <div className="Example mt-lg-1 mt-md-1">
                <div className="Example__container">
                    <div className="Example__container__document">
                        <Document file={selectedEdition.file} onLoadSuccess={onDocumentLoadSuccess} options={options} loading={Loader}>
                            <Page pageNumber={active} renderAnnotationLayer={false} renderTextLayer={false} />
                        </Document>
                    </div>
                </div>
            </div>}

            {pdfLoaded && <div className="d-flex justify-content-center pt-2">
                <Pagination>
                    <Pagination.First onClick={() => setActive(1)} />
                    {items.map(number =>
                        <Pagination.Item onClick={() => setActive(number)} key={number} active={number === active}>
                            {number}
                        </Pagination.Item>,
                    )}
                    <Pagination.Last onClick={() => setActive(numPages)} />
                </Pagination>
            </div>}
        </>
    );
}

export default Home;