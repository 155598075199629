import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useState } from "react";
import { Card, ProgressBar } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "../firebase";
import { createEdition } from '../services/edition.service';
import Navbar from "./Navbar";

function UploadForm() {
    // const [imgUrl, setImgUrl] = useState<string>();
    const [progresspercent, setProgresspercent] = useState(0);

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/login");
        if (error) console.log(error);
    }, [user, loading, error, navigate]);

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const date = new Date(e.target[0].value)
        const file = e.target[1]?.files[0]

        const dateStr = date.toLocaleDateString('en-GB').replaceAll("/", "-");

        if (!file) return;

        const storageRef = ref(storage, `epapers/${dateStr}.pdf`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                if (progress <= 90) {
                    setProgresspercent(progress);
                }
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    // setImgUrl(downloadURL)
                    await createEdition({ date, file: downloadURL, dateStr })
                    setProgresspercent(100);
                });
            }
        );
    }

    return (
        <>
            <Container>
                <Row>
                    <Col md={{ offset: 2, span: 8 }} lg={{ offset: 2, span: 8 }} xs={12}>
                        <Navbar />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="vh-90 d-flex justify-content-center align-items-center">
                    <Col md={6} lg={4} xs={12}>

                        <Card className="shadow">
                            <Card.Body>
                                <div className="mb-3 mt-md-4">
                                    <h3 className="fw-bold mb-4">Upload E-Paper</h3>
                                    <div className="mb-3">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label className="text-center">
                                                    Date
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>File</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Select file"
                                                    accept="application/pdf"
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicCheckbox"
                                            ></Form.Group>

                                            {
                                                progresspercent > 0 ?
                                                    <ProgressBar className="mb-4" variant="success" now={progresspercent} /> :
                                                    <div className="mb-4" style={{ height: '16px' }}></div>
                                            }

                                            <div className="d-grid">
                                                <Button
                                                    variant="danger"
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>

                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UploadForm;