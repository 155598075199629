import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { logout } from '../firebase';
import banner from '../images/banner-white.png';

function NavScrollExample() {
    return (
        <Navbar expand="lg" className="bg-danger  text-white">
            <Container fluid>
                <Navbar.Brand href="/" className='text-white'>
                <img src={banner} className="logo-xs" alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                    </Nav>
                    <NavDropdown title="Admin" id="navbarScrollingDropdown">
                        <NavDropdown.Item onClick={logout}>
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavScrollExample;